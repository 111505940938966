import React from 'react';
import { IconBookmarkFilled, IconChevronRight } from '@punchcard/core/icons';
import classNames from 'classnames';

interface IProps {
	activity: StudentCourseActivityDTO | TeacherCourseActivityDTO;
	activeActivity: number | null;
	handleActivityClick: (index: number | null, currentActivity: currentActivityAssessment) => void;
	statusCodeMappingActivity: statusCodeMappingActivity;
	isStudent: boolean;
	activityIndex: number;
	safeStatuses: string[];
}

function ActivitiesSummary({ activity, activeActivity, handleActivityClick, statusCodeMappingActivity, isStudent, activityIndex, safeStatuses }: IProps) {
	const isActive = activeActivity === activity?.id;
	const hasSkippedTag: boolean = activity && activity.courseActivityTags && activity.courseActivityTags.some(tag => tag.courseActivityTagCode === 'Skipped');
	const isActivityOpen = (activity && (activity.activityStatus == null || (activity.activityStatus && safeStatuses.includes(activity.activityStatus.statusCode)))) ?? true;
	const statusConfig = isActivityOpen && hasSkippedTag ? statusCodeMappingActivity['Skipped'] : statusCodeMappingActivity[activity?.activityStatus?.statusCode || 'Open'] || {};
	const statusConfigIcon = isActivityOpen && hasSkippedTag &&
		(!activity.activityStatus || (activity.activityStatus && activity.activityStatus.statusCode !== 'ReAttempt'))
		? statusCodeMappingActivity['Skipped']
		: statusCodeMappingActivity[activity?.activityStatus?.statusCode || 'Open'] || {};

	const IconComponent = statusConfigIcon.icon;
	return (
		<button
			className={classNames('activity', { 'active': isActive })}
			onClick={() => handleActivityClick(activity?.id, { type: 'Activity', item: activity })}
		>
			<div className="d-flex align-items-center flex-grow-1 overflow-hidden">
				<div className="d-flex align-items-center flex-shrink-0">
					{IconComponent && <IconComponent style={{ width: 18, height: 18 }} className={classNames('me-2', statusConfig.className)} />}
				</div>
				<div className="text-truncate flex-grow-1 text-start">
					{activityIndex + ': ' + activity?.activityName}
				</div>
			</div>
			<span className={
				classNames('status ms-3 flex-shrink-0',
					isActive ? statusConfig.activeClassName : statusConfig.className,
					{ 'text-decoration-underline': statusConfig.clickable && (!isActive || !isStudent) }
				)
			}>
				{isActive && isStudent ? (
					<div className="d-flex align-items-center">
						<IconBookmarkFilled className="me-2 text-primary-700" style={{ width: 14, height: 18 }} />
						{statusConfig.activeStatus}
					</div>
				) : (
					statusConfig.status
				)}
				{statusConfig.clickable && (!isActive || !isStudent) && (
					<IconChevronRight className={`ms-1 ${statusConfig.className}`} style={{ width: 18, height: 18 }} />
				)}
			</span>
		</button>
	);
}

export default ActivitiesSummary;