import React from 'react';

import classNames from 'classnames';

interface IProps {
	type: StudentCourseAssessmentTypeDTO | TeacherCourseAssessmentTypeDTO | undefined;
	active: StudentCourseAssessmentTypeDTO | TeacherCourseAssessmentTypeDTO | undefined;
	setActive: (type: StudentCourseAssessmentTypeDTO | TeacherCourseAssessmentTypeDTO | undefined) => void;
	assessmentData: { [key: string]: { title: string, description: string, illustration: string } };
}

function AssessmentCard(props: IProps) {
	const { type, active, setActive, assessmentData: data } = props;
	return (
		<div
			className={classNames('assessment-card', { 'active': active === type })}
			role="button"
			tabIndex={0}
			onClick={() => setActive(type)}
			onKeyPress={(e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					props.setActive && props.setActive(type);
				}
			}}
		>
			<div className="assessment-body">
				<h6>{data[type?.assessmentTypeCode as keyof typeof data].title}</h6>
				<small>{data[type?.assessmentTypeCode as keyof typeof data].description}</small>
			</div>
			<img src={data[type?.assessmentTypeCode as keyof typeof data].illustration} alt="Illustration Art" className="position-absolute top-0 end-0" />
		</div>
	);
}

export default AssessmentCard;