import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconDownload } from '@punchcard/core/icons';
import { Button } from '@punchcard/core';
import { Link } from 'react-router-dom';
import documentAPI from 'api/documentsAPI';

interface IProps {
	examAnswer?: TeacherCourseAssessmentTypeDTO;
}



function PBAAnswer(props: IProps) {
	const { examAnswer } = props;
	const { t } = useTranslation();

	const handleDownloadClick = async () => {
		if (examAnswer?.submittedFileNameFull) {
			const url = await documentAPI.getCourseDocumentByName(examAnswer?.submittedFileNameFull);
			if (url) {
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', examAnswer?.submittedFileName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	};
	return (
		<div className="assessment-body">
			<div className="form-label">{t('assessment.uploaded_file')}</div>
			<div style={{
				borderStyle: 'dashed', backgroundColor: '#F1F7F9', borderWidth: '2px'
			}} className="border-secondary-300  rounded-3 p-3 mt-2">
				<div className="border border-dark rounded-3 ps-2 bg-white d-flex justify-content-between align-items-center">
					<span style={{ textDecoration: 'underline' }}>{examAnswer?.submittedFileName}</span>
					<Button onClick={handleDownloadClick} style={{ border: 0 }} className="btn-outline-primary"><IconDownload style={{ fill: 'none' }} className="ms-2" /></Button>
				</div>
			</div>
			{examAnswer?.submittedLink &&
				<React.Fragment>
					<div className="form-label mt-3">{t('assessment.submitted_link')}</div>
					<div className="border rounded-3 p-3 mt-2">
						<Link target="_blank" to={examAnswer?.submittedLink}>{examAnswer?.submittedLink}</Link>
					</div>
				</React.Fragment>
			}
		</div>
	);
}

export default PBAAnswer;