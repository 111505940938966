

import React, { CSSProperties } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormGroup, Input, Button, RadioGroup } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import { IconClose } from '@punchcard/core/icons';

interface IGradeModalProps {
	setShowGradeModal: (showGradeModal: boolean) => void;
	currentActivity?: currentActivityAssessment | undefined;
	onStatusChange?: (courseActivityId: number | undefined, status: string, type: string | undefined, gradeReceived: number | undefined) => void;
}

interface ICourseGrade {
	activitystatus: string | undefined;
	gradeReceived: number | null | undefined;
}

const GradeModal = (props: IGradeModalProps) => {
	const { setShowGradeModal, currentActivity, onStatusChange } = props;
	const { t } = useTranslation();
	const methods = useForm<ICourseGrade>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			activitystatus: currentActivity?.item?.activityStatus?.statusCode,
			gradeReceived: currentActivity?.item?.gradeReceived,
		},
	});
	React.useEffect(() => {
		methods.reset({
			activitystatus: currentActivity?.item?.activityStatus?.statusCode,
			gradeReceived: currentActivity?.item?.gradeReceived,
		});
		//eslint-disable-next-line
	}, [currentActivity]);
	const radioButtonStatus = methods.watch('activitystatus');
	const { control, handleSubmit } = methods;
	const onSubmit = () => {
		const status: string = methods.getValues('activitystatus') ?? '';
		const grade: number | null | undefined = methods.getValues('gradeReceived');
		setShowGradeModal(false);
		onStatusChange && onStatusChange(currentActivity?.item?.id, status, currentActivity?.type, grade === null ? undefined : grade);
	};

	return (
		<div style={styles.modalOverlay} className={'d-flex position-absolute top-0 right-0 w-100 h-100'}>
			<div style={styles.modalContent} className={'rounded-start-3 border border-grey-200 p-3 bg-white h-100 w-50 position-absolute'}>
				<div className="d-flex justify-content-end">
					<Button
						onClick={() => setShowGradeModal(false)}
						className="btn-outline-primary border-0 pe-0"
					>
						<div className="text-decoration-underline">{t('navigation.close')}</div>
						<IconClose />
					</Button>
				</div>
				<Controller
					name={'activitystatus'}
					control={control}
					key={'activitystatus'}
					rules={{
						validate: () => {
							return (radioButtonStatus !== 'Completed' && radioButtonStatus !== 'ReAttempt')
								? t('error.this_field_is_required') // Return error message if validation fails
								: true; // Return true if validation passes
						}
					}}
					render={({ field, fieldState }) => {
						const onRadioChange = (e: unknown) => {
							if (e !== 'Completed') {
								methods.setValue('gradeReceived', null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
								methods.trigger('gradeReceived');
							}

							field.onChange(e);
						};
						return (
							<FormGroup label={'Status'} field={field} fieldState={fieldState}>
								<RadioGroup
									options={[
										{ label: 'Return to student', value: 'ReAttempt' },
										{ label: 'Pass', value: 'Completed' }
									]}
									value={field.value}
									onChange={onRadioChange}
								/>
							</FormGroup>
						);
					}} />
				{<Controller
					name={'gradeReceived'}
					control={control}
					key={'gradeReceived'}
					// rules={{
					// 	required: radioButtonStatus === 'Completed' ? t('error.this_field_is_required') : false,
					// 	max: { value: currentActivity?.item.gradeValue ?? 0, message: t('error.grade_cannot_exceed_maximum') },
					// }}
					render={({ field, fieldState }) => {
						return (
							<FormGroup label={'Grade'} required={true} field={field} fieldState={fieldState}>
								<Input
									type={'number'}
									value={field.value !== null ? field.value : ''}
									onChange={field.onChange}
									append={`/${currentActivity?.item.gradeValue}`}
									disabled={currentActivity?.item.gradeValue === 0 || radioButtonStatus !== 'Completed'}
									error={!!fieldState.error?.message}
								/>
							</FormGroup>
						);
					}} />
				}
				<Button
					className="btn-primary me-2 mb-2 position-absolute bottom-0 end-0"
					onClick={handleSubmit(onSubmit)}
				>
					{t('save')}
				</Button>
			</div>
		</div>
	);
};
const styles: { [key: string]: CSSProperties } = {
	modalOverlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		zIndex: 10000,
	},
	modalContent: {
		right: 0
	},
};
export default GradeModal;
