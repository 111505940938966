import React from 'react';
import { Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useResizeObserver } from '@wojtekmaj/react-hooks';

interface IProps {
	pdfData: File | null;
}

function Reader(props: IProps) {
	const { pdfData } = props;
	const [numPages, setNumPages] = React.useState<number>();
	const containerRef = React.useRef<HTMLDivElement | null>(null);
	const [containerWidth, setContainerWidth] = React.useState<number>();

	const maxWidth = 800;
	const resizeObserverOptions = {};

	const options = React.useMemo(() => {
		return {
			cMapUrl: '/cmaps/',
			standardFontDataUrl: '/standard_fonts/',
		};
	}, []);

	function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
		setNumPages(nextNumPages);
	}

	const onResize = React.useCallback<ResizeObserverCallback>((entries) => {
		const [entry] = entries;

		if (entry) {
			setContainerWidth(entry.contentRect.width);
		}
	}, []);

	useResizeObserver(containerRef.current, resizeObserverOptions, onResize);
	return (
		<div className="d-flex flex-column " ref={containerRef} aria-label="PDF viewer">
			<Document file={pdfData} options={options} onLoadSuccess={onDocumentLoadSuccess} loading={null}>
				{Array.from(new Array(numPages), (_el, index) => (
					<div key={`page_${index + 1}`}>
						<Page
							key={`page_${index + 1}`}
							pageNumber={index + 1}
							width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
							loading={null}
						/>
					</div>
				))}
			</Document>
		</div>
	);
}

export default Reader;