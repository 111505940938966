import React, { useEffect, useState } from 'react';
import { Button, EmptyData, LoadingScreen, Summary } from '@punchcard/core';
import { Bookmark, IconDoubleArrowLeft, IconDoubleArrowRight, IconList, IconRedWarning, IconCheck } from '@punchcard/core/icons';
import classNames from 'classnames';
import documentAPI from 'api/documentsAPI';
import { useTranslation } from 'react-i18next';
import ResourcesDocument from './ResourcesDocument';
import Reader from '@punchcard/core/components/Courses/Reader';

interface IProps {
	rtl?: boolean;
	course: TeacherCourseDTO | undefined;
	collapse: boolean;
	setCollapse: (collapse: boolean) => void;
	courseId: string | undefined;
	studentId: string | undefined;
	setCurrentActivity: (item: currentActivityAssessment) => boolean;
	statusCodeMappingActivity: statusCodeMappingActivity;
	safeStatuses: string[];
	activeUnit: number;
	setActiveUnit: (unit: number) => void;
	examReview: boolean;
	setExamReview: (value: boolean) => void;
	currentActivityItem: TeacherCourseActivityDTO | undefined;
	activeTab: number;
	setActiveTab: (tab: number) => void;
}

function Resources(props: IProps) {
	const { rtl = false, course, collapse, setCollapse, currentActivityItem,
		setCurrentActivity, statusCodeMappingActivity, safeStatuses, activeUnit, setActiveUnit, examReview, setExamReview, activeTab, setActiveTab } = props;
	const { t } = useTranslation();
	const [loadingCourseDocument, setLoadingCourseDocument] = useState<boolean>(false);
	const documentData = React.useRef<File | null>(null);
	const [activeLesson, setActiveLesson] = React.useState<null | number>(null);
	const [activeActivity, setActiveActivity] = React.useState<number | null>(null);
	const [openAccordions, setOpenAccordions] = React.useState<number[]>([]);
	const pba = currentActivityItem?.assessmentType && currentActivityItem?.assessmentType.find((type) => type.submittedDate !== undefined);
	const pdfExamData = React.useRef<File | null>(null);
	const handleTabClick = (index: number) => {
		documentData.current = null;
		setActiveTab(index);
	};

	const handleUnitClick = (index: number) => {
		setActiveUnit(index);
	};

	const handleLessonClick = (lessonId: number) => {
		if (lessonId === activeLesson) {
			setActiveLesson(null);
			return;
		}
		setActiveLesson(lessonId);

		setOpenAccordions((prevlessonIds) =>
			prevlessonIds.includes(lessonId)
				? prevlessonIds.filter((i) => i !== lessonId)
				: [...prevlessonIds, lessonId]
		);
	};

	const handleActivityClick = (index: number | null, currentActivity: currentActivityAssessment) => {
		const canUpdateActivity = setCurrentActivity(currentActivity);
		if (canUpdateActivity) {
			setActiveActivity(index);
		}
		// Leave Exam review mode if another activity is clicked
		if (examReview === true) {
			setExamReview(false);
		}
	};

	const statusCodeMappingLesson: statusCodeMappingLesson = {
		'Open': { className: 'text-gray-800', status: t('lesson_status.open') },
		'InProgress': { className: 'text-gray-800', status: t('lesson_status.in_progress') },
		'ReAttempt': { icon: IconRedWarning, activeIconClassName: 'text-danger-100', className: 'text-gray-800', status: t('lesson_status.reattempt') },
		'ReadyForReview': { icon: IconRedWarning, activeIconClassName: 'text-danger-100', className: 'text-gray-800', status: t('lesson_status.reattempt') },
		'Completed': { icon: IconCheck, activeIconClassName: 'text-success-100', className: 'text-success-600', status: t('lesson_status.completed') },
	};

	useEffect(() => {
		async function fetchCourseDocument(documentId: number | undefined) {
			try {
				setLoadingCourseDocument(true);
				if (documentId) {
					const documentUrl = await documentAPI.getCourseDocumentById(documentId);
					const response = await fetch(documentUrl);
					if (!response.ok) {
						throw new Error('error fetching document');
					}
					const blob = await response.blob();
					const document = new File([blob], 'document.pdf', { type: 'application/pdf' });
					documentData.current = document;
				}
			} catch (error) {
				console.error('error fetching courses', error);
			} finally {
				setLoadingCourseDocument(false);
			}
		}
		fetchCourseDocument(course?.courseDocuments?.[activeTab]?.id);
	}, [course, activeTab]);

	useEffect(() => {
		const lessons = course?.courseUnits[activeUnit]?.courseLessons;
		if (lessons) {
			setOpenAccordions(
				lessons
					.filter(lesson => lesson.courseLessonStatus?.statusCode !== 'Completed')
					.map(lesson => lesson.id)
			);
		}
	}, [activeUnit, course?.courseUnits]);

	useEffect(() => {
		if (examReview === true) {
			downloadPDF();
		} else {
			setActiveTab(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [examReview]);

	const downloadPDF = async () => {
		try {
			setLoadingCourseDocument(true);

			if (pba !== undefined) {
				documentAPI.getCourseDocumentById(pba.assessmentDocumentId)
					.then(async (res) => {

						const response = await fetch(res);
						if (!response.ok) {
							throw new Error('error fetching document');
						}
						const blob = await response.blob();
						const document = new File([blob], 'document.pdf', { type: 'application/pdf' });
						pdfExamData.current = document;
						setActiveTab(2);
					});
			}
		} catch (error) {
			console.error('Error fetching documents:', error);
			setLoadingCourseDocument(false);
		}
	};

	const renderTab = () => {
		if (loadingCourseDocument) {
			return <LoadingScreen />;
		}
		if (activeTab === 2) {
			return (
				<div>
					{currentActivityItem?.activityName !== undefined && <div className="d-flex">
						<span
							className={'no-action-tab ms-3 mt-2'}
							id={`tab-${2}`}
						>
							{currentActivityItem?.activityName}
						</span>
					</div>
					}
					<div className="flex-grow-1 overflow-y-auto" >
						<Reader pdfData={pdfExamData.current} />
					</div>
				</div>

			);
		}
		if (activeTab === 0 && course !== undefined) {
			return <Summary
				openAccordions={openAccordions}
				statusCodeMappingActivity={statusCodeMappingActivity}
				statusCodeMappingLesson={statusCodeMappingLesson}
				isStudent={false}
				course={course}
				activeUnit={activeUnit}
				handleUnitClick={handleUnitClick}
				handleLessonClick={handleLessonClick}
				activeActivity={activeActivity}
				handleActivityClick={handleActivityClick}
				safeStatuses={safeStatuses}
			/>;
		}

		if (documentData.current !== null) {
			return <ResourcesDocument
				course={course}
			/>;
		}

		return (
			<EmptyData
				emptymessage={t('wip.no_resources_for_this_unit')}
				descriptionmessage={t('wip.description_no_resources_for_this_unit')}
				image="empty-resources"
				widthdescription="w-75"
			/>
		);
	};

	return (
		<div
			className={classNames('flex-fill', { 'wrapper wip scroll-container-wrapper': collapse, 'position-absolute end-0': !collapse && rtl, 'position-absolute start-0': !collapse && !rtl })}
			aria-labelledby="resources component"
		>
			<div className="d-flex flex-column flex-grow-1 overflow-y-auto">
				<header id="resource-header" className={classNames('z-2 position-relative', rtl ? 'flex-row-reverse' : 'flex-row', collapse && 'd-flex justify-content-between')}>
					<Button
						className={classNames('btn-collapse rounded-bottom-0', rtl ? 'rounded-end-0 ms-3' : 'rounded-start-0 me-3')}
						onClick={() => setCollapse(!collapse)}
						aria-expanded={!collapse}
						aria-controls="resource-content"
					>
						{rtl && (collapse ? <IconDoubleArrowRight className="me-2 icon-27" /> : <IconDoubleArrowLeft className="me-2 icon-27" />)}
						{collapse ? t('wip.collapse') : t('wip.resources')}
						{!rtl && (collapse ? <IconDoubleArrowLeft className="ms-2 icon-27" /> : <IconDoubleArrowRight className="ms-2 icon-27" />)}
					</Button>

					{collapse && <div className="d-flex" id="resource-tabs">
						{
							<React.Fragment>
								{examReview === true && <div className="position-relative d-flex">
									<button
										className={classNames('resource-tab', 'ms-2', { active: 2 == activeTab })}
										onClick={() => handleTabClick(2)}
										aria-selected={1 === activeTab}
										aria-controls={`resource-tab-${2}`}
										id={`tab-${2}`}
										role="tab"
									>
										{pba?.assessmentTypeCode === 'exam' ? t('assessment.exam') : t('assessment.alt_assessment')}
									</button>
								</div>}
								<div className="position-relative d-flex">
									<button
										className={classNames('resource-tab', 'ms-2', { active: 0 == activeTab })}
										onClick={() => handleTabClick(0)}
										aria-selected={0 === activeTab}
										aria-controls={`resource-tab-${0}`}
										id={`tab-${0}`}
										role="tab"
									>
										<IconList />
									</button>
									{0 == activeTab && <Bookmark style={{ height: 17, bottom: -17, left: 5 }} className="position-absolute" aria-hidden="true" />}
								</div>
								<div className="position-relative d-flex">
									<button
										className={classNames('resource-tab', 'ms-2', { active: 1 == activeTab })}
										onClick={() => handleTabClick(1)}
										aria-selected={1 === activeTab}
										aria-controls={`resource-tab-${1}`}
										id={`tab-${1}`}
										role="tab"
									>
										{t('wip.resources')}
									</button>
								</div>
							</React.Fragment>
						}
					</div>}
				</header>
				{collapse && renderTab()}
			</div>
		</div>
	);
}

export default Resources;