import React from 'react';

import { IconDelete, IconMove } from '@punchcard/core/icons';
import ModalOverlay from './ModalOverlay';
import { TextArea } from './TextArea';
import { ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { Tooltip } from 'react-tooltip';
interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	text: TextBox;
	field: ControllerRenderProps<WIPForm, `studentLayer.${number}`>;
	firstPage: boolean;
	lastPage: boolean;
	activeTextBox: { page: number; id: string } | null;
	setActiveTextBox: (val: { page: number; id: string } | null) => void;
	pageIndex: number;
	mode: 'pencil' | 'eraser' | 'type' | 'hand' | 'resize';
}
const TextAreaContainer = (props: IProps) => {
	const { text, field, activeTextBox, mode, setActiveTextBox, pageIndex, firstPage, lastPage } = props;
	const { t } = useTranslation();
	const componentRef = React.useRef<HTMLDivElement>(null);
	const BUTTON_DIV_HEIGHT = 50;
	const pdfWindow = document.querySelector('.react-pdf__Document');
	const pdfWindowWidth = (pdfWindow?.clientWidth || 0) - 5;
	const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
	const initialPositionRef = React.useRef({ startX: 0, startY: 0, initialLeft: 0, initialTop: 0 });
	const initialRightWidthRef = React.useRef({ startX: 0, initialWidth: 0 });
	const initialLeftWidthRef = React.useRef({ startX: 0, width: 0, initialX: 0, initialWidth: 0 });
	const [showDeleteTextAreaModal, setShowDeleteTextAreaModal] = React.useState<boolean>(false);

	const styles = {
		container: {
			top: text.y,
			left: text.x,
			width: text.width,
		},
		outFocusText: {
			top: text.y,
			left: text.x,
			width: text.width,
			height: text.height,
			zIndex: mode === 'type' ? 1001 : 999,
			cursor: mode === 'type' ? 'text' : 'default',
		},
	};
	const handleDeleteTextAreaModal = () => {
		if (text?.content !== '') {	// if empty textbox, then just delete without confirmation
			setShowDeleteTextAreaModal(true);
		} else {
			handleDeleteTextArea(field);
		}
	};
	const handleDeleteTextArea = (field: ControllerRenderProps<WIPForm, `studentLayer.${number}`>) => {
		const updatedTyping = field.value.typing?.filter((t: { id: string }) => t.id !== text.id);
		field.onChange({ ...field.value, typing: updatedTyping });
		setShowDeleteTextAreaModal(false);  // Close the modal after deletion
	};

	React.useEffect(() => {
		setTimeout(() => {
			if (activeTextBox && textAreaRef.current) {
				textAreaRef.current.focus();
			}
		}, 50);
	}, [activeTextBox]);
	// ======== Resize Right =========
	const handleRightResizeDown = (event: React.MouseEvent) => {
		const targetElement = event.currentTarget.closest('.divTextBox') as HTMLElement;
		initialRightWidthRef.current.startX = event.clientX;
		initialRightWidthRef.current.initialWidth = parseFloat(targetElement.style.width || '0');
		document.addEventListener('mousemove', handleRightResizeMove);
		document.addEventListener('mouseup', handleRightResizeUp);
	};

	const handleRightResizeMove = (event: MouseEvent) => {
		const typingArray = field.value.typing || [];
		const deltaWidth = event.clientX - initialRightWidthRef.current.startX;
		const newWidth = initialRightWidthRef.current.initialWidth + deltaWidth;
		const targetIndex = typingArray.findIndex((t: { id: string }) => t.id === text.id);
		if (targetIndex != -1 && newWidth <= (pdfWindowWidth - text.x) && newWidth >= 100) {
			const updatedTyping = field.value.typing?.map((t: { id: string }) => t.id === text.id ? { ...t, width: newWidth } : t);
			field.onChange({ ...field.value, typing: updatedTyping });
		}
	};
	const handleRightResizeUp = () => {
		document.removeEventListener('mousemove', handleRightResizeMove);
		document.removeEventListener('mouseup', handleRightResizeUp);
	};
	// ======== Resize Left =========
	const handleLeftResizeDown = (event: React.MouseEvent) => {
		const targetElement = event.currentTarget.closest('.divTextBox') as HTMLElement;
		initialLeftWidthRef.current.startX = event.clientX;
		initialLeftWidthRef.current.initialX = parseFloat(targetElement.style.left || '0');
		initialLeftWidthRef.current.initialWidth = parseFloat(targetElement.style.width || '0');
		document.addEventListener('mousemove', handleLeftResizeMove);
		document.addEventListener('mouseup', handleLeftResizeUp);
	};

	const handleLeftResizeMove = (event: MouseEvent) => {
		const typingArray = field.value.typing || [];
		const deltaWidth = event.clientX - initialLeftWidthRef.current.startX;
		const newWidth = initialLeftWidthRef.current.initialWidth - deltaWidth;
		const newLeft = initialLeftWidthRef.current.initialX + deltaWidth;
		const targetIndex = typingArray.findIndex((t: { id: string }) => t.id === text.id);
		if (targetIndex != -1 && newLeft >= 0 && newWidth >= 200) {
			const target = typingArray[targetIndex];
			target.width = newWidth;
			target.x = newLeft;
			const updatedTyping = field.value.typing?.map((t: { id: string }) => t.id === text.id ? { ...t, width: newWidth, x: newLeft } : t);
			field.onChange({ ...field.value, typing: updatedTyping });
		}
	};
	const handleLeftResizeUp = () => {
		document.removeEventListener('mousemove', handleLeftResizeMove);
		document.removeEventListener('mouseup', handleLeftResizeUp);
	};

	const handleMouseDown = (event: React.MouseEvent) => {
		const targetElement = event.currentTarget.closest('.divTextBox') as HTMLElement;
		initialPositionRef.current.startX = event.clientX;
		initialPositionRef.current.startY = event.clientY;
		initialPositionRef.current.initialLeft = parseFloat(targetElement.style.left || '0');
		initialPositionRef.current.initialTop = parseFloat(targetElement.style.top || '0');
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
	};
	const handleMouseMove = (event: MouseEvent) => {
		const targetElement = event.target as HTMLElement;
		const divTextBox = targetElement.closest('.divTextBox') as HTMLElement;
		const divText = divTextBox && divTextBox.querySelector<HTMLTextAreaElement>('textarea') as HTMLElement;
		const textboxHeight = divText?.clientHeight || text.height;
		const deltaX = event.clientX - initialPositionRef.current.startX;
		const deltaY = event.clientY - initialPositionRef.current.startY;
		const newLeft = initialPositionRef.current.initialLeft + deltaX;
		const newTop = initialPositionRef.current.initialTop + deltaY;
		if (!targetElement) { return; }
		const typingArray = field.value.typing || [];
		const targetIndex = typingArray.findIndex((t: { id: string }) => t.id === text.id);
		const divPage = targetElement.closest('.divPage') as HTMLElement;
		if (divPage && targetIndex != -1 &&
			newLeft >= 0 &&	//Can't move too far left
			newLeft <= (pdfWindowWidth - text.width) && // can't move too far right
			!(newTop < BUTTON_DIV_HEIGHT && firstPage) && // can't move too far up
			!(newTop > (divPage.clientHeight - textboxHeight) && lastPage)	// can't move too far down
		) {
			const updatedTyping = field.value.typing?.map((t: { id: string }) => t.id === text.id ? { ...t, x: newLeft, y: newTop } : t);
			field.onChange({ ...field.value, typing: updatedTyping });
		}
	};

	// Stop dragging
	const handleMouseUp = () => {
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
	};


	React.useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			const target = event.target as HTMLElement;
			if (target.closest('.modal.show')) {
				return; // Exit if clicked inside a visible modal
			}
			if (componentRef &&
				componentRef.current &&
				!componentRef.current.contains(event.target as Node)
			) {
				setActiveTextBox(null);
				setShowDeleteTextAreaModal(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setActiveTextBox]);


	if (activeTextBox?.id === text.id) {
		return (

			<div ref={componentRef}
				className={'divTextBox textAreaContainer'}
				style={styles.container as React.CSSProperties}
			>
				<Tooltip id="my-tooltip" place="top" />
				<Button
					className="border-secondary-500 p-0 cursor-ew-resize leftHandle"
					onMouseDown={(e) => handleLeftResizeDown(e)}

				>
					<div className="border border-secondary-500 handleBox" />
				</Button>
				<Button
					className="border-secondary-500 p-0 cursor-ew-resize rightHandle"
					onMouseDown={(e) => handleRightResizeDown(e)}
				>
					<div className="border border-secondary-500 handleBox" />
				</Button>
				<div
					className="border-secondary-500 bg-secondary-100 divButtons"
				>

					<Button
						onMouseDown={(e) => handleMouseDown(e)}
						className="btn-ghost-primary-300 p-2 btn-blue-hover"
						data-tooltip-id="my-tooltip" data-tooltip-content="Move" data-tooltip-place="top"
					>
						<IconMove width="10px" />
					</Button>
					<Button
						onClick={() => handleDeleteTextAreaModal()}
						className="btn-ghost-primary-300 p-2 btn-blue-hover"
						data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-tooltip-place="top"
					>
						<IconDelete width="10px" />
					</Button>
				</div>
				<ModalOverlay
					isOpen={showDeleteTextAreaModal}
					modalSize="md"
					onRequestClose={() => setShowDeleteTextAreaModal(false)}
					headerChildren={t('wip.delete_text_area')}
					confirmButtonChildren={t('wip.confirm')}
					cancelButtonChildren={t('wip.cancel')}
					hideCloseButton={true}
					confirmButtonClassName="btn-danger bg-danger-500"
					shouldCloseOnOverlayClick={false}
					confirmButtonAction={() => {
						handleDeleteTextArea(field);
					}}
					cancelButtonAction={() => {
						setShowDeleteTextAreaModal(false);
					}}
				>
					{t('wip.are_you_sure_you_want_to_delete_this_text_area')}
				</ModalOverlay>
				<TextArea
					className="position-absolute border-secondary-500 siblingElement textArea"
					key={`fieldtext-${text.id}`}
					placeholder="Start Typing"
					value={text.content}
					// eslint-disable-next-line jsx-a11y/no-autofocus
					ref={textAreaRef}
					onMouseDown={(e) => e.stopPropagation()}
					onBlur={(e) => {
						field.onChange(
							{
								...field.value,
								typing: field.value?.typing?.map((t) => {
									if (t.id === text.id) {

										return {
											...t,
											width: e.currentTarget.getBoundingClientRect().width,
											height: e.currentTarget.getBoundingClientRect().height
										};
									}
									return t;
								}),
							},
						);
					}}
					onInput={(e) => {
						const target = e.target as HTMLTextAreaElement;
						target.style.height = `${target.scrollHeight + 2}px`;  // Set height to scrollHeight
					}}
					onFocus={(e) => {
						const target = e.target as HTMLTextAreaElement;
						target.style.height = `${target.scrollHeight + 2}px`;  // Set height to scrollHeight
					}}
					onChange={(v) => field.onChange(
						{
							...field.value,
							typing: field.value?.typing?.map((t) => {
								if (t.id === text.id) {
									return { ...t, content: v.target.value };
								}
								return t;
							}),
						},
					)}
				/>

			</div>
		);
	} else {
		return (
			<div
				className="position-absolute hover-border outFocusText"
				draggable="true"
				onMouseDown={(e) => {
					if (mode === 'type') {
						e.stopPropagation();
						setActiveTextBox({ page: pageIndex, id: text.id });
					} else {
						e.preventDefault();
					}
				}}
				role="textbox"
				tabIndex={0}
				aria-label="Text box"
				style={styles.outFocusText as React.CSSProperties}
			>
				{text.content}
			</div>
		);
	}
};

export default TextAreaContainer;
