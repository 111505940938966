import React from 'react';
import classNames from 'classnames';
import { IconCheck, IconClose, IconEdit } from '@punchcard/core/icons';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';
interface IProps {
	currentActivity?: currentActivityAssessment;
	statusCodeMappingActivity: statusCodeMappingActivity;
	onStatusChange?: (courseActivityId: number | undefined, status: string, type: string | undefined, gradeReceived: number | undefined) => void;
	setGradeModalOpen: (isOpen: boolean) => void;
	gradeModalOpen: boolean;
}

function PDFReaderButtonsTeacher(props: IProps) {
	const { currentActivity, statusCodeMappingActivity, setGradeModalOpen, gradeModalOpen } = props;
	const status = currentActivity?.item?.activityStatus?.statusCode;
	const { t } = useTranslation();
	const getCustomStatusTitle = (status: string) => {
		switch (status) {
		case 'ReAttempt':
			return <div><IconClose className={'me-2'} />{t('activity_status.returned_to_student')}</div>;
		case 'Completed':
			return <div ><IconCheck className={'me-2'} />{t('activity_status.completed')}</div>;
		case 'ReadyForReview':
			return '';
		default:
			return statusCodeMappingActivity[status]?.status;
		}
	};
	const getCustomStatusClass = (status: string) => {
		switch (status) {
		case 'ReAttempt':
			return 'text-danger-600';
		default:
			return statusCodeMappingActivity[status]?.className;
		}
	};
	function displayButtons(status: string | undefined) {
		const customStatus = status && getCustomStatusTitle(status);
		const customStatusClass = status && getCustomStatusClass(status);
		switch (status) {
		case 'ReAttempt':
		case 'ReadyForReview':
		case 'Completed':
			return (
				<React.Fragment>
					<div className={classNames('p-1', customStatusClass)}>{customStatus}</div>
					<Button
						className={classNames('btn-collapse rounded-bottom-0 bg-secondary-600 text-white ms-auto', 'rounded-start-0')}
						onClick={() => setGradeModalOpen(!gradeModalOpen)}
						aria-expanded={!gradeModalOpen}
						aria-controls="grade"
					>
						<IconEdit className="me-2 icon-27" />
							Grade
					</Button>
				</React.Fragment>
			);
		default:
			return (
				<div className="p-1"></div>
			);
		}
	}

	return (
		<div className="d-flex align-items-center bg-quaternary-400 py-2 px-3 rounded-top-3 pe-0" style={{ minHeight: 55 }} >
			{displayButtons(status)}

		</div>
	);
}

export default PDFReaderButtonsTeacher;