import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Button } from '@punchcard/core';
import { IconCheck, IconEraser, IconPencil, IconRedo, IconUndo, IconRedWarning, IconHand, IconType } from '@punchcard/core/icons';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

interface IProps {
	updateMode: (mode: 'pencil' | 'eraser' | 'hand' | 'type') => void;
	mode: 'pencil' | 'eraser' | 'hand' | 'type';
	statusCodeMappingActivity: statusCodeMappingActivity;
	currentActivity?: currentActivityAssessment;
}

function PDFReaderButtonsStudent(props: IProps) {
	const { statusCodeMappingActivity, currentActivity } = props;
	const { watch, setValue } = useFormContext<WIPForm>();
	const [undoHistory, undoIndex] = watch(['undoHistory', 'undoIndex']);
	const newUndoIndexUndo = undoIndex === null ? 0 : undoIndex + 1;
	const newUndoIndexRedo = undoIndex === null ? 0 : undoIndex - 1;
	const status = currentActivity?.item?.activityStatus?.statusCode ? currentActivity?.item?.activityStatus?.statusCode : 'Open';
	function displayButtons(status: string | undefined) {
		switch (status) {
			case 'Open':
				return (
					studentButton()
				);
			case 'InProgress':
				return (
					studentButton()
				);
			case 'ReAttempt':
				return (
					studentButton(true, status)
				);
			case 'ReadyForReview':
				return (
					<div className={classNames('p-1 d-flex align-items-center flex-fill justify-content-end', statusCodeMappingActivity[status]?.className)}>
						<IconCheck className="icon-27" />
						{statusCodeMappingActivity[status]?.status}
					</div>
				);
			case 'Completed':
				return (
					<div className={classNames('p-1 d-flex align-items-center flex-fill justify-content-end', statusCodeMappingActivity[status]?.className)}>
						<IconCheck className="icon-27" />
						{statusCodeMappingActivity[status]?.status}
					</div>
				);
			default:
				return (
					<div className="p-1"></div>
				);
		}
	}

	function studentButton(ReAttempt: boolean = false, status: string | undefined = '') {
		return (
			<div className="d-flex justify-content-between flex-fill">
				<div className="d-flex align-items-center bg-quaternary-400 w-100">
					<Tooltip id="my-tooltip" place="top" />
					{/* Wrapping the buttons in a span to allow tooltip to show on disabled buttons */}
					<span data-tooltip-id="my-tooltip" data-tooltip-content="Undo" data-tooltip-place="top">
						<Button
							className="btn-ghost-primary border-0"
							onClick={() => {
								setValue('lastAction', 'undo');
								setValue('undoIndex', newUndoIndexUndo);
							}}
							disabled={undoHistory.length === 0 || undoIndex === undoHistory.length - 1}
							aria-label="Undo"
						>
							<IconUndo />
						</Button>
					</span>
					<span data-tooltip-id="my-tooltip" data-tooltip-content="Redo">
						<Button
							className="btn-ghost-primary border-0"
							onClick={() => {
								setValue('lastAction', 'redo');
								setValue('undoIndex', newUndoIndexRedo);
							}}
							disabled={undoIndex === null || undoHistory.length === 0 || undoIndex === -1}
							aria-label="Redo"
						>
							<IconRedo />
						</Button>
					</span>
					<Button
						className={classNames('btn-ghost-primary border-0', props.mode === 'hand' && 'active')}
						onClick={() => props.updateMode('hand')}
						data-tooltip-id="my-tooltip"
						data-tooltip-content="Move"
						aria-label="Move"
					>
						<IconHand />
					</Button>
					<Button
						className={classNames('btn-ghost-primary border-0', props.mode === 'pencil' && 'active')}
						onClick={() => props.updateMode('pencil')}
						data-tooltip-id="my-tooltip"
						data-tooltip-content="Draw"
						aria-label="Draw"
					>
						<IconPencil />
					</Button>
					<Button
						className={classNames('btn-ghost-primary border-0', props.mode === 'eraser' && 'active')}
						onClick={() => props.updateMode('eraser')}
						data-tooltip-id="my-tooltip"
						data-tooltip-content="Erase"
						aria-label="Erase"
					>
						<IconEraser />
					</Button>
					<Button
						className={classNames('btn-ghost-primary border-0', 'p-3', props.mode === 'type' && 'active')}
						onClick={() => props.updateMode('type')}
						data-tooltip-id="my-tooltip"
						data-tooltip-content="Type"
						aria-label="Type"
					>
						<IconType />
					</Button>
				</div>
				{ReAttempt &&
					<div className={classNames('p-1 d-flex align-items-center', statusCodeMappingActivity[status]?.className)}>
						<IconRedWarning className="icon-27" />
						{statusCodeMappingActivity[status]?.status}
					</div>
				}
			</div>
		);
	}


	return (
		<div className="d-flex align-items-center bg-quaternary-400 px-3 rounded-top-3" style={{ minHeight: 55 }} >
			{displayButtons(status)}
		</div>
	);
}

export default PDFReaderButtonsStudent;